import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: `"Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif`,
    body: `"Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif`,
  },
  components: {
    IconButton: { baseStyle: { _focus: { boxShadow: "none" } } },
    Button: { baseStyle: { _focus: { boxShadow: "none" } } },
  },
  styles: {
    global: {
      body: {
        backgroundColor: "gray.100",
      },
    },
  },
});
