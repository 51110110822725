import React from "react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { ChakraProvider } from "@chakra-ui/react";
import { RecoilRoot } from "recoil";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import { theme } from "../theme";
import Layout from "./layout/layout";
import initAuth from "../initAuth";

initAuth();

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={theme}>
      <RecoilRoot>
        <DndProvider backend={HTML5Backend}>
          <Layout>
            <Head>
              <title> Askq </title>
            </Head>
            <Component {...pageProps} />
          </Layout>
        </DndProvider>
      </RecoilRoot>
    </ChakraProvider>
  );
}

export default MyApp;
