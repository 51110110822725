import Link from "next/link";
import Image from "next/image";
import { Box } from "@chakra-ui/react";
import { useAuth } from "../../../hooks/useAuth";

const Logo = () => {
  const { isAuthenticated } = useAuth();
  return (
    <Box p={2} cursor={"pointer"} data-testid="appBarLogo">
      <Link href={isAuthenticated ? "/home" : "/"}>
        <a>
          <Image
            src="/images/header_logo.png"
            width={"80px"}
            height={"60px"}
            objectFit={"contain"}
            alt={"Askq"}
          />
        </a>
      </Link>
    </Box>
  );
};

export default Logo;
