import { useEffect, useState } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut as signOutFirebase,
  User,
  deleteUser,
} from "firebase/auth";
import * as Sentry from "@sentry/browser";
import { useToast } from "@chakra-ui/react";
import { generateToastArg } from "../domain/toast";
import { useRouter } from "next/router";
import { app, auth } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const authState = atom({
  key: "authState",
  default: false,
});

export const useAuth = () => {
  const isAuthenticated = useRecoilValue(authState);
  const setIsAuthenticated = useSetRecoilState(authState);
  const router = useRouter();
  const toast = useToast();

  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);

  // Google認証プロバイダを初期化
  const googleProvider = new GoogleAuthProvider();
  const twitterProvider = new TwitterAuthProvider();
  // 言語を設定
  auth.languageCode = "ja";

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      // getAuth(),
      auth,
      (user) => {
        if (user) {
          setUser(user);
          setIsAuthenticated(true);
          setIsLoading(false);
          Sentry.setUser({
            id: user.uid,
            email: user.email ?? "",
            username: user.displayName ?? "",
          });
        } else {
          setIsAuthenticated(false);
          setIsLoading(false);
        }
      },
      (error) => console.error(error)
    );

    return unsubscribe;
  }, [setIsAuthenticated]);

  /**
   * 認証ポップアップを出してユーザをサインインさせる
   * @param provider 認証プロバイダ
   */
  const signIn = (provider: GoogleAuthProvider | TwitterAuthProvider) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
        // アカウントの作成日と最終ログイン日が一致する場合(=新規ユーザの場合)はアカウント情報登録画面へ遷移
        if (
          result.user.metadata.creationTime ===
          result.user.metadata.lastSignInTime
        ) {
          router.push("/account-registration");
        } else {
          router.push("/home");
        }
      })
      .catch((e) => {
        console.error(e);
        toast(generateToastArg("ログインに失敗しました", "error"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Googleアカウントでログイン
   */
  const signInWithGoogle = () => {
    signIn(googleProvider);
  };

  /**
   * Twitterアカウントでログイン
   */
  const signInWithTwitter = () => {
    signIn(twitterProvider);
  };

  const signOut = async () => {
    await signOutFirebase(auth);
    Sentry.configureScope((scope) => scope.setUser(null));
    router.push("/");
  };

  /**
   * ID TokenのClaimを取得する
   */
  const getIdTokenClaims = async () => {
    await auth.currentUser?.getIdToken(true);
    const idToken = await auth.currentUser?.getIdTokenResult();
    if (idToken) {
      return idToken.claims;
    }
  };

  /**
   * ユーザを削除する
   */
  const deleteUserAccount = async () => {
    if (user) {
      // ログインしてしばらく時間が経過するとエラーとなるので
      user.reload();
      // ユーザが作成した公開済みアンケートを削除するCloud Functionsを呼び出し
      const functions = getFunctions(app, "asia-northeast1");
      const functionRef = httpsCallable(
        functions,
        "deletePublishedEnqueteByOwnerId"
      );
      await functionRef();

      // Firestore上の公開アンケートを削除してから、認証情報を削除する
      // 逆にしてしまうとユーザが消えているためデータへアクセスできなくなる
      await deleteUser(user);
      setUser(undefined);
      setIsAuthenticated(false);

      router.replace("/");
    }
  };

  return {
    isAuthenticated,
    user,
    signInWithGoogle,
    signInWithTwitter,
    signOut,
    getIdTokenClaims,
    isLoading,
    deleteUserAccount,
  };
};
