import { AlertStatus, UseToastOptions } from "@chakra-ui/react";

export const generateToastArg = (
  title: string,
  status?: AlertStatus
): UseToastOptions => ({
  title,
  status,
  duration: 3000,
  isClosable: true,
  position: "top",
});
