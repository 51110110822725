import Link from "next/link";
import { Flex, Heading, Stack, Text } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Stack bg={"white"} p={6}>
      <Flex
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={{ base: "flex-start", md: "center" }}
        gap={"10px 50px"}
      >
        <Heading size={"sm"} width={"150px"}>
          &copy; Askq
        </Heading>
        <Link href="/about">
          <Text size={"sm"} minWidth={"150px"} style={{ cursor: "pointer" }}>
            Askqについて
          </Text>
        </Link>
        <Link href="/inquiry">
          <Text size={"sm"} minWidth={"150px"} style={{ cursor: "pointer" }}>
            お問い合わせ
          </Text>
        </Link>
        <Link href="/usage-terms">
          <Text size={"sm"} minWidth={"150px"} style={{ cursor: "pointer" }}>
            利用規約
          </Text>
        </Link>
        <Link href="/privacy-policy">
          <Text size={"sm"} minWidth={"150px"} style={{ cursor: "pointer" }}>
            プライバシーポリシー
          </Text>
        </Link>
      </Flex>
    </Stack>
  );
};

export default Footer;
