import Link from "next/link";
import { IoPersonCircleSharp, IoMenu, IoLogOutOutline } from "react-icons/io5";
import { AiFillStar, AiFillHome } from "react-icons/ai";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spacer,
  Text,
} from "@chakra-ui/react";

import { useAuth } from "../../../hooks/useAuth";
import Logo from "./Logo";
import { useRouter } from "next/router";

const Header = () => {
  const { isAuthenticated, isLoading, signOut } = useAuth();
  const router = useRouter();

  const shouldHideButton =
    router.pathname === "/account-registration" ||
    router.pathname === "/thankyou" ||
    router.pathname === "/login" ||
    router.pathname.startsWith("/enquete/");

  return (
    <Flex
      bg="white"
      width={"100%"}
      height={"16"}
      alignItems={"center"}
      gap={2}
      boxShadow={"sm"}
    >
      <Logo />
      <Spacer />
      <Box mr="4" alignItems={"center"}>
        {shouldHideButton || isLoading ? (
          <></>
        ) : isAuthenticated ? (
          <Box data-testid={"userMenu"}>
            <Menu>
              <MenuButton as={IconButton} aria-label="menu" icon={<IoMenu />} />
              <MenuList>
                <Link href="/home">
                  <MenuItem icon={<AiFillHome />}>Homeへ戻る</MenuItem>
                </Link>
                <Link href="/account">
                  <MenuItem icon={<IoPersonCircleSharp />}>
                    アカウント情報
                  </MenuItem>
                </Link>
                {process.env.NEXT_PUBLIC_FEATURE_PRO_PLAN === "on" && (
                  <Link href="/plan">
                    <MenuItem icon={<AiFillStar />}>料金プラン</MenuItem>
                  </Link>
                )}
                <MenuItem icon={<IoLogOutOutline />} onClick={signOut}>
                  ログアウト
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        ) : (
          <Box cursor={"pointer"} data-testid={"signInButton"}>
            <Link href="/login">
              <Text fontWeight={"bold"}>ログイン</Text>
            </Link>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default Header;
