import { Box, Flex } from "@chakra-ui/react";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";

const Layout: React.FC = ({ children }) => {
  return (
    <Flex direction={"column"} minHeight={"100vh"}>
      <Box>
        <Header />
      </Box>
      <Box flex={1} minHeight={"700px"}>
        {children}
      </Box>
      <Box>
        <Footer />
      </Box>
    </Flex>
  );
};

export default Layout;
